import { createAsyncThunk } from '@reduxjs/toolkit';
import { push } from 'connected-react-router';
import { FirebaseAuthError } from 'my-firebase-wrapper';
import {
  OrderQueries,
  WhereQueries,
} from 'my-firebase-wrapper/dist/firestore/types';

import { auth } from '../../repositories/firebase';
import { collaboratorRepository } from '../../repositories/firebase/collaborator';
import { AsyncThunkConfig } from '../store';
import {
  CollaboratorFormType,
  CollaboratorListTableType,
  CollaboratorType,
  MyAccountFormType,
} from './types';
import { OpenSnackbar } from '../../presentation/hooks/useSnackbar';
import { reset as resetEvent } from '../event/slice';
import { reset as resetUser } from '../user/slice';
import { updateUser } from '../../domain/service/updateUser';

export const onAuthStateChanged = createAsyncThunk<
  string,
  undefined,
  AsyncThunkConfig<null>
>('collaborator/onAuthStateChanged', async (_, thunkAPI) => {
  try {
    const user = await auth.onAuthStateChanged();
    if (!user) throw new Error();

    return user.uid;
  } catch (error) {
    thunkAPI.dispatch(push('/login'));

    return thunkAPI.rejectWithValue(null);
  }
});

export const signIn = createAsyncThunk<
  CollaboratorType,
  { email: string; password: string; openSnackbar: OpenSnackbar },
  AsyncThunkConfig<FirebaseAuthError>
>(
  'collaborator/signIn',
  async ({ email, password, openSnackbar }, thunkAPI) => {
    try {
      const userCredential = await auth.signInWithEmailAndPassword(
        email,
        password,
      );
      if (!('user' in userCredential) || !userCredential.user) {
        throw userCredential;
      }
      const { user } = userCredential;
      const collaborator = await collaboratorRepository.fetchByDocId(user.uid);
      if (!collaborator) {
        throw new Error('ユーザーの取得に失敗しました');
      }
      thunkAPI.dispatch(push('/selectMaker/'));

      return collaborator;
    } catch (error: any) {
      console.error(error);
      openSnackbar({
        variant: 'error',
        message:
          'メールアドレスまたはパスワードが違います' ||
          'ログインできませんでした。もう一度お試しください。',
      });
      thunkAPI.rejectWithValue(error);

      return error;
    }
  },
);

export const signOut = createAsyncThunk<
  undefined,
  undefined,
  AsyncThunkConfig<undefined>
>('collaborator/signOut', async (_, thunkAPI) => {
  try {
    await auth.signOut();
    thunkAPI.dispatch(resetEvent());
    thunkAPI.dispatch(resetUser());
    thunkAPI.dispatch(push('/login'));

    return undefined;
  } catch (error) {
    thunkAPI.dispatch(push('/login'));

    return thunkAPI.rejectWithValue(undefined);
  }
});

export const setMyAccount = createAsyncThunk<
  CollaboratorType,
  { formValue: MyAccountFormType; openSnackbar: OpenSnackbar },
  AsyncThunkConfig<undefined>
>('collaborator/setMyAccount', async (args, thunkAPI) => {
  try {
    const { collaborator } = thunkAPI.getState().collaborator;
    const { name, mailAddress, password, currentPassword } = args.formValue;
    const myAccount = { ...collaborator, name };

    // 入力したメールアドレスが現在のメールアドレスと違う or 新しいパスワードを入力している
    if (collaborator.mailAddress !== mailAddress || password) {
      await auth.reauthenticateWithCredential(currentPassword);

      // メールアドレスの更新
      if (collaborator.mailAddress !== mailAddress) {
        await auth.updateEmail(mailAddress);
        myAccount.mailAddress = mailAddress;
      }
      // パスワードの更新
      if (password) {
        await auth.updatePassword(password);
      }
    }

    await collaboratorRepository.update(myAccount);

    args.openSnackbar({
      variant: 'success',
      message: 'アカウントの更新が完了しました',
    });

    return myAccount;
  } catch (error: any) {
    let msg = '';
    switch (error?.code) {
      case 'auth/invalid-email': {
        msg = 'ご入力いただいたメールアドレスは無効な形式です';
        break;
      }
      case 'auth/email-already-in-use': {
        msg = 'ご入力いただいたメールアドレスのアカウントは既に存在します';
        break;
      }
      case 'auth/user-not-found': {
        msg = 'メールアドレスまたはパスワードが違います';
        break;
      }
      case 'auth/user-disabled': {
        msg = 'ご指定のアカウントは無効化されています';
        break;
      }
      case 'auth/weak-password': {
        msg =
          'ご入力いただいたパスワードが脆弱です。6文字以上入力してください。';
        break;
      }
      case 'auth/wrong-password': {
        msg = 'メールアドレスまたはパスワードが違います';
        break;
      }
      default: {
        msg = 'アカウントの更新ができませんでした。もう一度お試しください。';
      }
    }
    args.openSnackbar({
      variant: 'error',
      message: msg,
    });

    return thunkAPI.rejectWithValue(undefined);
  }
});

export const fetchCollaborators = createAsyncThunk<
  CollaboratorListTableType[],
  {
    whereQueries: WhereQueries<CollaboratorType>;
    orderQueries: OrderQueries<CollaboratorType>;
  },
  AsyncThunkConfig<undefined>
>('collaborator/fetchCollaborators', async (args, thunkAPI) => {
  try {
    const { whereQueries, orderQueries } = args;
    let collectionRef: any = collaboratorRepository;
    whereQueries.forEach(({ fieldPath, opStr, value }) => {
      collectionRef = collectionRef.where(fieldPath, opStr, value);
    });
    orderQueries.forEach(({ fieldPath, directionStr }) => {
      collectionRef = collectionRef.orderBy(fieldPath, directionStr);
    });
    const collaborators: CollaboratorType[] =
      'fetch' in collectionRef
        ? await collectionRef.fetch()
        : await collectionRef.fetchAll();

    return collaborators || [];
  } catch (error) {
    console.error(error);

    return thunkAPI.rejectWithValue(undefined);
  }
});

export const setCollaborator = createAsyncThunk<
  CollaboratorType,
  { formValue: CollaboratorFormType; openSnackbar: OpenSnackbar },
  AsyncThunkConfig<undefined>
>('collaborator/setCollaborator', async (args, thunkAPI) => {
  try {
    const { uid, name, mailAddress, role, password } = args.formValue;
    const newValue = { uid, name, mailAddress, role };
    const { collaboratorTableData } = thunkAPI.getState().collaborator;
    const beforeCollaboratorData = collaboratorTableData.find(
      (c) => c.uid === uid,
    );

    if (uid) {
      await updateUser(
        uid,
        beforeCollaboratorData?.mailAddress === mailAddress
          ? {
              password,
            }
          : {
              email: mailAddress,
              password,
            },
      );
      await collaboratorRepository.update(newValue);
      args.openSnackbar({
        variant: 'success',
        message: 'コラボレーターの更新が完了しました',
      });
    } else {
      const userCredential = await auth.createUserWithEmailAndPassword(
        mailAddress,
        password,
      );
      if ('user' in userCredential && userCredential.user?.uid) {
        const userId = userCredential.user.uid;
        newValue.uid = userId;
        await collaboratorRepository.add(newValue);
        args.openSnackbar({
          variant: 'success',
          message: 'コラボレーターの作成が完了しました',
        });
        thunkAPI.dispatch(push('/collaborator'));
      }
    }

    return newValue;
  } catch (error: any) {
    args.openSnackbar({
      variant: 'error',
      message:
        error?.message ||
        'アカウントの更新ができませんでした。もう一度お試しください。',
    });

    return thunkAPI.rejectWithValue(undefined);
  }
});
