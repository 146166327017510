import React, { FC } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useSelector } from 'react-redux';
import moment from 'moment';
import { Typography } from '@material-ui/core';
import { TextField } from '../../../base/form/TextField';
import { useStyles } from './useStyles';
import { ErrorMessage } from '../../../base/form/ErrorMessage';
import {
  coverageWorks,
  CoverageWorkTypes,
  PERFORMANCE_STANDARDS,
  STRENGTHS,
  SPECIALIZED_FIELDS,
  COMPANY_TYPES,
  MakerType,
  SUPPORT_MEETINGS,
  SUPPORT_MONIES,
  SUPPORT_LANDS,
} from '../../../../../re-ducks/maker/types';
import { SelectField } from '../../../base/form/SelectField';
import { MakerCoverageAreas } from '../MakerCoverageAreas';
import { MakerFeatures } from '../MakerFeatures';
import FormSection from '../../../case/form/FormSection';
import FormTable from '../../../case/form/FormTable';
import TableRow from '../../../case/form/FormTable/TableRow';
import TsuboSlider from '../TsuboSlider';
import { AddPhoto } from '../../../base/form/AddPhoto';
import { InputLabel } from '../../../base/form/InputLabel';
import { MakerSalesOfficeInput } from '../MakerSalesOfficeInput';
import { DateField } from '../../../base/form/DateField';
import { formats } from '../../../../../data/date';
import { Images } from '../../../case/form/Images';
import { selectMaker } from '../../../../../re-ducks/maker/selector';
import Tooltip from '../../../base/Tooltip';
import TooltipImageGoogleMap from '../../../../../assets/images/tooltip_googlemap.jpg';

const Main: FC = () => {
  const maker = useSelector(selectMaker);
  const defaultValues: MakerType = { ...maker };
  const classes = useStyles();
  const formMethods = useFormContext();
  const { errors, watch, control, register, getValues, setValue } = formMethods;

  return (
    <div className="main">
      <FormSection sectionTitle="スライダー画像">
        <Images />
      </FormSection>
      <FormSection sectionTitle="この会社の特徴・強み">
        <FormTable>
          <TableRow label={<InputLabel>坪単価</InputLabel>} verticalAlign="top">
            <div className={classes.group}>
              <h3 className={classes.title}>注文住宅の坪単価</h3>
              <TsuboSlider
                name="tsuboPriceCustomHouse"
                defaultValue={defaultValues.tsuboPriceCustomHouse}
              />
              <ErrorMessage error={errors.tsuboPriceCustomHouse} />
            </div>
            <div className={classes.group}>
              <h3 className={classes.title}>リノベーションの坪単価</h3>
              <TsuboSlider
                name="tsuboPriceRenovation"
                defaultValue={defaultValues.tsuboPriceRenovation}
              />
              <ErrorMessage error={errors.tsuboPriceRenovation} />
            </div>
            <div className={classes.group}>
              <h3 className={classes.title}>リフォームの坪単価</h3>
              <TsuboSlider
                name="tsuboPriceReform"
                defaultValue={defaultValues.tsuboPriceReform}
              />
              <ErrorMessage error={errors.tsuboPriceReform} />
            </div>
          </TableRow>
          <TableRow label={<InputLabel>会社の種類</InputLabel>}>
            <Controller
              name="companyType"
              control={control}
              render={({ name }) => (
                <SelectField
                  name={name}
                  defaultValue={
                    defaultValues.companyType
                      ? {
                          label: COMPANY_TYPES[defaultValues.companyType],
                          value: defaultValues.companyType,
                        }
                      : null
                  }
                  options={(
                    Object.keys(COMPANY_TYPES) as (keyof typeof COMPANY_TYPES)[]
                  ).map((type) => ({
                    label: COMPANY_TYPES[type],
                    value: type,
                  }))}
                />
              )}
              isClearable
            />
            <ErrorMessage error={errors.companyType} />
          </TableRow>
          <TableRow label={<InputLabel>対応業務</InputLabel>}>
            <Controller
              name="coverageWorks"
              control={control}
              render={({ name }) => (
                <SelectField
                  name={name}
                  defaultValue={defaultValues.coverageWorks.map((work) => {
                    return {
                      label: coverageWorks[work],
                      value: work,
                    };
                  })}
                  options={(
                    Object.keys(coverageWorks) as CoverageWorkTypes[]
                  ).map((work) => ({
                    label: coverageWorks[work],
                    value: work,
                  }))}
                  isMulti
                />
              )}
              isClearable
            />
            <ErrorMessage error={errors.coverageWorks} />
          </TableRow>
          <TableRow
            label={<InputLabel>会社の強み</InputLabel>}
            verticalAlign="top"
          >
            <div className={classes.group}>
              <h3 className={classes.title}>1番目の会社の強み</h3>
              <Controller
                name="strength1st"
                control={control}
                rules={{
                  validate: {
                    notOverlapping: (value) =>
                      value && watch('strength2nd') === value
                        ? '会社の強みに重複した値があります'
                        : true,
                  },
                }}
                render={({ name }) => (
                  <SelectField
                    name={name}
                    defaultValue={
                      defaultValues.strength1st
                        ? {
                            label: STRENGTHS[defaultValues.strength1st],
                            value: defaultValues.strength1st,
                          }
                        : null
                    }
                    options={(
                      Object.keys(STRENGTHS) as (keyof typeof STRENGTHS)[]
                    ).map((strength) => ({
                      label: STRENGTHS[strength],
                      value: strength,
                    }))}
                  />
                )}
                isClearable
              />
              <ErrorMessage error={errors.strength1st} />
            </div>
            <div className={classes.group}>
              <h3 className={classes.title}>2番目の会社の強み</h3>
              <Controller
                name="strength2nd"
                control={control}
                rules={{
                  validate: {
                    notOverlapping: (value) =>
                      value && watch('strength1st') === value
                        ? '会社の強みに重複した値があります'
                        : true,
                  },
                }}
                render={({ name }) => (
                  <SelectField
                    name={name}
                    defaultValue={
                      defaultValues.strength2nd
                        ? {
                            label: STRENGTHS[defaultValues.strength2nd],
                            value: defaultValues.strength2nd,
                          }
                        : null
                    }
                    options={(
                      Object.keys(STRENGTHS) as (keyof typeof STRENGTHS)[]
                    ).map((strength) => ({
                      label: STRENGTHS[strength],
                      value: strength,
                    }))}
                  />
                )}
                isClearable
              />
              <ErrorMessage error={errors.strength2nd} />
            </div>
          </TableRow>
          <TableRow
            label={<InputLabel>得意な分野</InputLabel>}
            verticalAlign="top"
          >
            <div className={classes.group}>
              <h3 className={classes.title}>1番目に得意な分野</h3>
              <Controller
                name="specializedField1st"
                control={control}
                rules={{
                  validate: {
                    notOverlapping: (value) =>
                      value &&
                      (watch('specializedField2nd') === value ||
                        watch('specializedField3rd') === value)
                        ? '得意な分野に重複した値があります'
                        : true,
                  },
                }}
                render={({ name }) => (
                  <SelectField
                    name={name}
                    defaultValue={
                      defaultValues.specializedField1st
                        ? {
                            label:
                              SPECIALIZED_FIELDS[
                                defaultValues.specializedField1st
                              ],
                            value: defaultValues.specializedField1st,
                          }
                        : null
                    }
                    options={(
                      Object.keys(
                        SPECIALIZED_FIELDS,
                      ) as (keyof typeof SPECIALIZED_FIELDS)[]
                    ).map((strength) => ({
                      label: SPECIALIZED_FIELDS[strength],
                      value: strength,
                    }))}
                  />
                )}
                isClearable
              />
              <ErrorMessage error={errors.specializedField1st} />
            </div>
            <div className={classes.group}>
              <h3 className={classes.title}>2番目に得意な分野</h3>
              <Controller
                name="specializedField2nd"
                control={control}
                rules={{
                  validate: {
                    notOverlapping: (value) =>
                      value &&
                      (watch('specializedField1st') === value ||
                        watch('specializedField3rd') === value)
                        ? '得意な分野に重複した値があります'
                        : true,
                  },
                }}
                render={({ name }) => (
                  <SelectField
                    name={name}
                    defaultValue={
                      defaultValues.specializedField2nd
                        ? {
                            label:
                              SPECIALIZED_FIELDS[
                                defaultValues.specializedField2nd
                              ],
                            value: defaultValues.specializedField2nd,
                          }
                        : null
                    }
                    options={(
                      Object.keys(
                        SPECIALIZED_FIELDS,
                      ) as (keyof typeof SPECIALIZED_FIELDS)[]
                    ).map((strength) => ({
                      label: SPECIALIZED_FIELDS[strength],
                      value: strength,
                    }))}
                  />
                )}
                isClearable
              />
              <ErrorMessage error={errors.specializedField2nd} />
            </div>
            <div className={classes.group}>
              <h3 className={classes.title}>3番目に得意な分野</h3>
              <Controller
                name="specializedField3rd"
                control={control}
                rules={{
                  validate: {
                    notOverlapping: (value) =>
                      value &&
                      (watch('specializedField1st') === value ||
                        watch('specializedField2nd') === value)
                        ? '得意な分野に重複した値があります'
                        : true,
                  },
                }}
                render={({ name }) => (
                  <SelectField
                    name={name}
                    defaultValue={
                      defaultValues.specializedField3rd
                        ? {
                            label:
                              SPECIALIZED_FIELDS[
                                defaultValues.specializedField3rd
                              ],
                            value: defaultValues.specializedField3rd,
                          }
                        : null
                    }
                    options={(
                      Object.keys(
                        SPECIALIZED_FIELDS,
                      ) as (keyof typeof SPECIALIZED_FIELDS)[]
                    ).map((strength) => ({
                      label: SPECIALIZED_FIELDS[strength],
                      value: strength,
                    }))}
                  />
                )}
                isClearable
              />
              <ErrorMessage error={errors.specializedField3rd} />
            </div>
          </TableRow>
          <TableRow label={<InputLabel>性能</InputLabel>}>
            <div>
              <Controller
                name="performanceStandards"
                control={control}
                render={({ name }) => (
                  <SelectField
                    name={name}
                    defaultValue={defaultValues.performanceStandards.map(
                      (performance) => {
                        return {
                          label: PERFORMANCE_STANDARDS[performance],
                          value: performance,
                        };
                      },
                    )}
                    options={(
                      Object.keys(
                        PERFORMANCE_STANDARDS,
                      ) as (keyof typeof PERFORMANCE_STANDARDS)[]
                    ).map((performance) => ({
                      label: PERFORMANCE_STANDARDS[performance],
                      value: performance,
                    }))}
                    isMulti
                  />
                )}
                isClearable
              />
              <ErrorMessage error={errors.performanceStandards} />
            </div>
          </TableRow>
          <TableRow
            label={<InputLabel>対応内容</InputLabel>}
            verticalAlign="top"
          >
            <div className={classes.group}>
              <h3 className={classes.title}>打ち合わせについて</h3>
              <Controller
                name="supportMeetings"
                control={control}
                render={({ name }) => (
                  <SelectField
                    name={name}
                    defaultValue={defaultValues.supportMeetings.map(
                      (support) => {
                        return {
                          label: SUPPORT_MEETINGS[support],
                          value: support,
                        };
                      },
                    )}
                    options={(
                      Object.keys(
                        SUPPORT_MEETINGS,
                      ) as (keyof typeof SUPPORT_MEETINGS)[]
                    ).map((support) => ({
                      label: SUPPORT_MEETINGS[support],
                      value: support,
                    }))}
                    isMulti
                  />
                )}
                isClearable
              />
              <ErrorMessage error={errors.supportMeetings} />
            </div>
            <div className={classes.group}>
              <h3 className={classes.title}>お金について</h3>
              <Controller
                name="supportMonies"
                control={control}
                render={({ name }) => (
                  <SelectField
                    name={name}
                    defaultValue={defaultValues.supportMonies.map((support) => {
                      return {
                        label: SUPPORT_MONIES[support],
                        value: support,
                      };
                    })}
                    options={(
                      Object.keys(
                        SUPPORT_MONIES,
                      ) as (keyof typeof SUPPORT_MONIES)[]
                    ).map((support) => ({
                      label: SUPPORT_MONIES[support],
                      value: support,
                    }))}
                    isMulti
                  />
                )}
                isClearable
              />
              <ErrorMessage error={errors.supportMonies} />
            </div>
            <div className={classes.group}>
              <h3 className={classes.title}>土地について</h3>
              <Controller
                name="supportLands"
                control={control}
                render={({ name }) => (
                  <SelectField
                    name={name}
                    defaultValue={defaultValues.supportLands.map((support) => {
                      return {
                        label: SUPPORT_LANDS[support],
                        value: support,
                      };
                    })}
                    options={(
                      Object.keys(
                        SUPPORT_LANDS,
                      ) as (keyof typeof SUPPORT_LANDS)[]
                    ).map((support) => ({
                      label: SUPPORT_LANDS[support],
                      value: support,
                    }))}
                    isMulti
                  />
                )}
                isClearable
              />
              <ErrorMessage error={errors.supportLands} />
            </div>
          </TableRow>
          <TableRow
            label={<InputLabel>対応可能エリア</InputLabel>}
            verticalAlign="top"
          >
            <MakerCoverageAreas />
          </TableRow>
        </FormTable>
      </FormSection>
      <FormSection sectionTitle="コンセプト">
        <div className={classes.group}>
          <TextField
            label="タイトル"
            type="text"
            name="concept.title"
            inputRef={register}
            placeholder=""
            error={!!errors?.concept?.title}
          />
          <ErrorMessage error={errors?.concept?.title} />
        </div>
        <div className={classes.group}>
          <TextField
            label="YouTube共有コード"
            type="text"
            name="concept.youtube"
            inputRef={register}
            error={!!errors?.concept?.youtube}
          />
          <ErrorMessage error={errors?.concept?.youtube} />
        </div>
        <div className={classes.group}>
          <InputLabel>画像</InputLabel>
          <input type="hidden" name="concept.image" ref={register} />
          <div style={{ width: 200, height: 200 }} className="mt-4">
            <AddPhoto
              name="concept.image"
              jpegUrl={watch('concept.image')}
              setJpegUrl={(url) => {
                setValue('concept.image', url);
              }}
              accept="image/png,image/jpeg"
            />
          </div>
        </div>
        <div className={classes.group}>
          <TextField
            label="説明文"
            type="textarea"
            name="concept.text"
            inputRef={register({ required: '説明文を入力してください' })}
            error={!!errors?.concept?.text}
            required
            multiline
            rows={4}
          />
          <ErrorMessage error={errors?.concept?.text} />
        </div>
      </FormSection>
      <FormSection sectionTitle="特徴">
        <MakerFeatures />
      </FormSection>
      <FormSection sectionTitle="会社概要">
        <FormTable>
          <TableRow label={<InputLabel>会社名</InputLabel>}>
            <div className={classes.group}>
              <TextField
                type="text"
                name="companyName"
                inputRef={register}
                error={!!errors?.companyName}
              />
              <ErrorMessage error={errors?.companyName} />
            </div>
          </TableRow>
          <TableRow
            label={<InputLabel noAsterisk>所在地</InputLabel>}
            verticalAlign="top"
          >
            <div className={classes.group}>
              <TextField
                label="郵便番号"
                type="text"
                name="postalCode"
                inputRef={register}
                error={!!errors?.postalCode}
              />
              <ErrorMessage error={errors?.postalCode} />
            </div>
            <div className={classes.group}>
              <TextField
                label="住所"
                type="text"
                name="address"
                inputRef={register}
                error={!!errors?.address}
              />
              <ErrorMessage error={errors?.address} />
            </div>
            <div className={classes.group}>
              <TextField
                label={
                  <>
                    Gooleマップの共有リンク
                    <Tooltip>
                      <div>
                        <ol className="mb-3">
                          <li>パソコンで Google マップを開きます。</li>
                          <li>
                            共有するルート、地図、ストリートビュー画像に移動します。
                          </li>
                          <li>
                            左上のメニュー アイコン メニュー をクリックします。
                          </li>
                          <li>
                            <span className="has-text-weight-bold">
                              [地図を共有または埋め込む]
                            </span>
                            を選択します。この選択肢が表示されない場合、
                            <span className="has-text-weight-bold">
                              [この地図へのリンク]
                            </span>
                            をクリックします。
                          </li>
                          <li>
                            リンクをコピーして、マップを共有する場所に貼り付けます。
                          </li>
                        </ol>
                        <img
                          src={TooltipImageGoogleMap}
                          alt=""
                          width={600}
                          height={300}
                        />
                      </div>
                    </Tooltip>
                  </>
                }
                type="text"
                name="googleMapUrl"
                inputRef={register}
                error={!!errors?.googleMapUrl}
                placeholder={`<iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d12595.727114238682!2d139.0012454!3d37.8852781!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x5ff4c87064e47ac1%3A0x4e7349192625d1f3!2z5qCq5byP5Lya56S-44Kv44O844ON44Or44Ov44O844Kv!5e0!3m2!1sja!2sjp!4v1655945276563!5m2!1sja!2sjp" width="600" height="450" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>`}
              />
              <ErrorMessage error={errors?.googleMapUrl} />
            </div>
            <div className={classes.group}>
              <TextField
                label="電話番号"
                type="tel"
                name="phoneNumber"
                inputRef={register({
                  required: '電話番号を入力してください',
                })}
                required
                error={!!errors?.phoneNumber}
              />
              <ErrorMessage error={errors?.phoneNumber} />
            </div>
          </TableRow>
          <TableRow
            label={<InputLabel noAsterisk>営業所</InputLabel>}
            verticalAlign="top"
          >
            <MakerSalesOfficeInput />
          </TableRow>
          <TableRow label={<InputLabel>代表者</InputLabel>}>
            <TextField type="text" name="representative" inputRef={register} />
            <ErrorMessage error={errors?.representative} />
          </TableRow>
          <TableRow label={<InputLabel>設立日</InputLabel>}>
            <TextField
              type="text"
              name="establishmentDate"
              inputRef={register}
            />
            <ErrorMessage error={errors?.establishmentDate} />
          </TableRow>
          <TableRow label={<InputLabel>資本金</InputLabel>}>
            <TextField type="text" name="capitalStock" inputRef={register} />
            <ErrorMessage error={errors?.capitalStock} />
          </TableRow>
          <TableRow label={<InputLabel>従業員数</InputLabel>}>
            <TextField
              type="text"
              name="numberOfEmployees"
              inputRef={register}
            />
            <ErrorMessage error={errors?.numberOfEmployees} />
          </TableRow>
          <TableRow label={<InputLabel>事業内容</InputLabel>}>
            <TextField
              type="textarea"
              name="business"
              inputRef={register}
              rows={4}
              multiline
            />
            <ErrorMessage error={errors?.business} />
          </TableRow>
          <TableRow label={<InputLabel>認可・免許</InputLabel>}>
            <TextField
              type="textarea"
              name="approvalLicenses"
              inputRef={register}
              rows={4}
              multiline
            />
            <ErrorMessage error={errors?.approvalLicenses} />
          </TableRow>
          <TableRow
            label={<InputLabel>Webサイト・SNS</InputLabel>}
            verticalAlign="top"
          >
            <div className={classes.group}>
              <h3 className={classes.title}>Webサイト</h3>
              <TextField
                type="textarea"
                name="webSiteUrl"
                inputRef={register}
              />
              <ErrorMessage error={errors?.webSiteUrl} />
            </div>
            <div className={classes.group}>
              <h3 className={classes.title}>Twitter</h3>
              <TextField type="textarea" name="twUrl" inputRef={register} />
              <ErrorMessage error={errors?.twUrl} />
            </div>
            <div className={classes.group}>
              <h3 className={classes.title}>Instagram</h3>
              <TextField type="textarea" name="igUrl" inputRef={register} />
              <ErrorMessage error={errors?.igUrl} />
            </div>
            <div className={classes.group}>
              <h3 className={classes.title}>Facebook</h3>
              <TextField type="textarea" name="fbUrl" inputRef={register} />
              <ErrorMessage error={errors?.fbUrl} />
            </div>
          </TableRow>
        </FormTable>
      </FormSection>
    </div>
  );
};

export default Main;
